import firebase from 'firebase';
import axios from 'axios'
import * as constantURL from '../constants'

export const initializeFirebase = () => {
    var firebaseConfig = {
    apiKey: "AIzaSyAkMl3-5EtEHDzm2Cnl6YjFZsOajuzEYDU",
    authDomain: "villive.firebaseapp.com",
    databaseURL: "https://villive.firebaseio.com",
    projectId: "villive",
    storageBucket: "villive.appspot.com",
    messagingSenderId: "181110717950",
    appId: "1:181110717950:web:cb61e7fcd2e0d4187094af",
    measurementId: "G-QN4ZQJ9WE0"
  };
  firebase.initializeApp(firebaseConfig);
}

export const askForPermissioToReceiveNotifications = async (successCallbackFn,errCallbackFn) => {
   try {
      const messaging = firebase.messaging();
      console.log("After messaging")
      await messaging.requestPermission();
      console.log("After requestPermission")
      const token = await messaging.getToken();
      console.log("After token : ", token)
      sessionStorage.setItem("gcmId", token)
      await registerdevice(token,successCallbackFn,errCallbackFn);
      return token;
    }
    catch (error) 
    {
        console.log("Error occured :  notifications: "+ error)
        //errCallbackFn("p");
    }
  }

  export const registerdevice = async (token,successCallbackFn,errCallbackFn) =>{
    console.log("inside registerdevice")
    var isSuccess = false;
    var url = `${constantURL.USER_REGISTRATION_API_URL}`
    var deviceID = generateDeviceId();
    console.log("deviceID : ", deviceID)
    sessionStorage.setItem("deviceId", deviceID)

    var data = {
      "appName": constantURL.APP_NAME,
      "deviceId": sessionStorage.getItem("deviceId"),
      "gcmId": token,
      "msisdn": sessionStorage.getItem("msisdn").length==10? '91'+sessionStorage.getItem("msisdn"):sessionStorage.getItem("msisdn"),
      "appVersionName": "1.0",
      "appVersionCode": 1.7,
      "loginLogoutFlag": false
    }
    //'access_token': sessionStorage.getItem("accessToken"),

      var headers = {
        "Content-Type"  :"application/json",
        "Device_id"    	: sessionStorage.getItem("deviceId"),
        "Accept"        :"application/json",
        "channel"       :'web',
        "access_token"  :sessionStorage.getItem("accessToken")
      };

      console.log("url to hit : ", url);
      console.log("headers: "+JSON.stringify(headers));
      console.log("body: "+JSON.stringify(data));
      await axios.post(url,	data,{headers: headers}
        ).then(response=>{
          console.log("response from notificaation", response.data);
          isSuccess = true
          //successCallbackFn();
          return isSuccess;
          
        }) .catch(error=>{
          console.log("Error occured : while calling registered user API : ", error)
          isSuccess = false;
          return isSuccess;
        });
  }

  export function generateDeviceId(){
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    console.log("uid : ", uid);
    return uid.toString();
  }
